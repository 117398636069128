<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <nav id="app-menu" :class="[{collapsed: collapseMenu}]">
    <div>
      <header>
        <router-link to="/">
          <logo></logo>
          <strong>Alta Call</strong>
        </router-link>
        <a @click.prevent="collapseMenu = !collapseMenu" href="" class="collapse-menu">
          <app-icon class="large" v-if="collapseMenu" name="menu" />
          <app-icon class="large" v-else name="close" />
        </a>
      </header>
      <div class="menu px-regular" v-if="$store.state.groups.length > 1">
        <div class="field">
          <label for="group-menu">
            <translate translate-context="*/*/*/Noun">Current group</translate>
          </label>
          <select
            name="group-menu"
            id="group-menu"
            :value="$store.state.selectedGroupId"
            @change="$store.commit('selectBestGroup', $event.target.value); $router.push({name: $route.name, query: $route.query, params: {...$route.params, group: $event.target.value}})">
            <option
              v-for="group in $store.state.groups"
              :key="group.id" :value="group.id">{{ group.name }}</option>
          </select>
        </div>
      </div>
      <div class="menu">
        <router-link to="/account" v-if="$store.state.authenticated">
          <app-icon name="account-circle" /> {{ $store.state.user.first_name || $store.state.user.name.split(" ")[0] }}
        </router-link>
        <router-link to="/login" v-else>
          <app-icon name="login" />
          <translate translate-context="*/*/*/Verb">Login</translate>
        </router-link>
      </div>
      <div class="menu" v-if="$store.state.authenticated && $store.state.user.permissions.manage_accounts">
        <router-link to="/organization">
          <app-icon name="domain" />
          <translate translate-context="*/*/*">Organization</translate>
        </router-link>
        <div class="submenu" v-if="$store.state.user.permissions.client_admin && $route.path.startsWith('/organization')">
          <router-link to="/organization/settings">
            <translate translate-context="*/*/*">Settings</translate>
          </router-link>
        </div>
        <div class="submenu" v-if="$route.path.startsWith('/organization')">
          <router-link to="/organization/groups">
            <translate translate-context="*/*/*/Noun">Groups</translate>
          </router-link>
        </div>
        <div class="submenu" v-if="$route.path.startsWith('/organization')">
          <router-link to="/organization/accounts">
            <translate translate-context="*/*/*">Accounts</translate>
          </router-link>
        </div>
      </div>
      <div class="menu" v-if="$store.state.selectedGroupId">
        <router-link :to="{name: 'dashboard', params: {group: $store.state.selectedGroupId}}">
          <app-icon name="dashboard" />
          <translate translate-context="*/*/*">Dashboard</translate>
        </router-link>
        <div class="submenu" v-if="$route.path.startsWith('/dashboard')">
          <router-link :to="{name: 'dashboard.group', params: {group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*/Noun">Group</translate>
          </router-link>
          <router-link :to="{name: 'dashboard.users', params: {group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*">Users</translate>
          </router-link>
          <router-link :to="{name: 'dashboard.machines', params: {group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*">Machines</translate>
          </router-link>
          <router-link :to="{name: 'dashboard.issues', params: {group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*">Issues</translate>
          </router-link>
          <router-link :to="{name: 'dashboard.labels', params: {group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*">Labels</translate>
          </router-link>
          <router-link :to="{name: 'dashboard.abtests', params: {group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*">A/B Tests</translate>
          </router-link>
          <router-link :to="{name: 'dashboard.outdated-machines', params: {group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*">Outdated machines</translate>
          </router-link>
        </div>
      </div>
      <div class="menu">
        <router-link to="/help">
          <app-icon name="help-circle" />
          <translate translate-context="*/*/*">Help center</translate>
        </router-link>

        <div class="submenu" v-if="$route.path.startsWith('/help')">
          <router-link to="/help/docs">
            <translate translate-context="*/*/*">Documentation</translate>
          </router-link>
          <router-link to="/help/software-overview">
            <translate translate-context="*/*/*">Software overview</translate>
          </router-link>
          <router-link to="/help/setup">
            <translate translate-context="*/*/*">Setup checklist</translate>
          </router-link>
          <router-link to="/help/faq">
            <translate translate-context="*/*/*">FAQ</translate>
          </router-link>
          <router-link to="/help/api">
            <translate translate-context="*/*/*">API</translate>
          </router-link>
        </div>
      </div>
      <div class="menu">
        <router-link to="/downloads">
          <app-icon name="download" />
          <translate translate-context="*/*/*/Noun">Downloads</translate>
        </router-link>
      </div>
      <div class="menu" v-if="$store.state.authenticated">
      <router-link to="/feedback">
          <app-icon name="forum" />
          <translate translate-context="*/*/*/Verb">Share your feedback</translate>
        </router-link>
      </div>
    </div>
    <div class="menu px-regular py-regular">
      <div class="field">

        <label for="language">
          <translate translate-context="*/*/*/Noun">Language</translate>
        </label>
        <select
          id="language"
          name="language"
          :value="$language.current"
          @change="$store.commit('currentLanguage', $event.target.value)"
        >
          <option
            v-for="(language, key) in $language.available"
            :key="key"
            :value="key"
          >{{ language }}</option>
        </select>
      </div>
    </div>
  </nav>
</template>
<script>
import Logo from './Logo'
export default {
  components: {
    Logo
  },
  data () {
    return {
      collapseMenu: true,
    }
  }
}
</script>
