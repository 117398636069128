<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <button :disabled="isLoading">
    <app-icon v-if="isLoading" name="refresh" class="rotating"></app-icon>
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: {
    isLoading: {type: Boolean, default: false}
  }
}
</script>