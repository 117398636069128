// Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted.

import { createApp } from 'vue'
import setupSentry from "./sentry"
import App from './App.vue'
import router from './router'
import { createGettext } from "@jshmrtn/vue3-gettext"
import translations from './language/translations'
import AppButton from '@/components/AppButton.vue'
import AppIcon from '@/components/AppIcon.vue'
import ApiForm from '@/components/ApiForm.vue'
import AppModal from '@/components/AppModal.vue'
import EmptyState from '@/components/EmptyState.vue'
import FieldErrors from '@/components/FieldErrors.vue'
import LoadingArea from '@/components/LoadingArea.vue'
import store from './store'

import locales from "./language"

console.log('Initializing app…')
console.log('API URL: ', store.state.apiUrl)

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.authenticated) {
    next({name: 'login', query: {next: to.fullPath}})
  } else {
    next()
  }
})

let availableLanguages = {}
locales.locales.forEach(l => {
  availableLanguages[l.code] = l.label
})

const gettext = createGettext({
  defaultLanguage: store.state.currentLanguage || 'en_US',
  mutedLanguages: store.state.currentLanguage || 'en_US',
  availableLanguages,
  translations
});
const app = createApp(App)
if (process.env.VUE_APP_SENTRY_DSN) {
  setupSentry(app)
}


app.use(store).use(router)
app.use(gettext)

app.component('api-form', ApiForm)
app.component('app-button', AppButton)
app.component('app-icon', AppIcon)
app.component('app-modal', AppModal)
app.component('empty-state', EmptyState)
app.component('field-errors', FieldErrors)
app.component('loading-area', LoadingArea)

app.mount('#app')
