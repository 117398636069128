<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->

<template>
  <svg
    viewBox="0 0 24 24"
    class="icon"
    :class="styleClasses"
  >
    <path :d="path" />
  </svg>
</template>

<script>
import {
  mdiAccountCircle,
  mdiAccountMultiple,
  mdiAlertCircleOutline,
  mdiBell,
  mdiBook,
  mdiChartBox,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCalendar,
  mdiClose,
  mdiCloseCircleOutline,
  mdiCodeTags,
  mdiDomain,
  mdiDownload,
  mdiHelpCircle,
  mdiLaptop,
  mdiLink,
  mdiLock,
  mdiLogin,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline,
  mdiMenu,
  mdiMenuDown,
  mdiMenuUp,
  mdiForum,
  mdiPencil,
  mdiPower,
  mdiRefresh,
  mdiShieldAccountOutline,
  mdiTimerSand,
  mdiTrashCan,
  mdiUpload,
  mdiViewDashboard,
  mdiVolumeOff,
  mdiVolumeHigh,
  mdiWrench,
} from '@mdi/js';

const icons = {
  "account-circle": mdiAccountCircle,
  "account-multiple": mdiAccountMultiple,
  "alert-circle-outline": mdiAlertCircleOutline,
  "bell": mdiBell,
  "book": mdiBook,
  "calendar": mdiCalendar,
  "check": mdiCheck,
  "close": mdiClose,
  "chevron-left": mdiChevronLeft,
  "chevron-right": mdiChevronRight,
  "code-tags": mdiCodeTags,
  "dashboard": mdiViewDashboard,
  "domain": mdiDomain,
  "download": mdiDownload,
  "laptop": mdiLaptop,
  "chart-box": mdiChartBox,
  "forum": mdiForum,
  "help-circle": mdiHelpCircle,
  "link": mdiLink,
  "lock": mdiLock,
  "login": mdiLogin,
  "magnify-minus-outline": mdiMagnifyMinusOutline,
  "magnify-plus-outline": mdiMagnifyPlusOutline,
  "menu": mdiMenu,
  "menu-down": mdiMenuDown,
  "menu-up": mdiMenuUp,
  "close-circe-outline": mdiCloseCircleOutline,
  "pencil": mdiPencil,
  "power": mdiPower,
  "refresh": mdiRefresh,
  "shield-account-outline": mdiShieldAccountOutline,
  "upload": mdiUpload,
  "timer-sand": mdiTimerSand,
  "volume-off": mdiVolumeOff,
  "volume-high": mdiVolumeHigh,
  "trash-can": mdiTrashCan,
  "wrench": mdiWrench,
};

export default {
  props: {
    name: String,
    size: String,
    color: String,
    title: String,
    desc: String,
  },
  computed: {
    path() {
      return icons[this.name];
    },
    styleClasses() {
      return [this.size, this.color].map(val => val && `is-${val}`);
    },
  },
};
</script>