<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div class="text-centered">
    <app-icon name="close"></app-icon>&nbsp;
    <slot>
      <span>
        <translate translate-context="*/*/Empty State">No results to display</translate>
      </span>
    </slot>
    <slot name="controls"></slot>
  </div>
</template>